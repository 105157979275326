<template>
    <div class="login">
        <h2>{{'auth.welcome' | translate}}</h2>
        <form method="post" id="login_form" @submit.prevent="onSubmit" name="login">
            <div class="form-group with-icon-right" :class="{'has-error': errors.has('email'), 'valid': isSuccessfulEmailValid}">
                <div class="input-group">
                    <input id="email" name="email" v-model="email" v-validate="'required|email'" />
                    <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                    <i class="fa fa-check valid-icon icon-right input-icon"></i>
                    <label class="control-label" for="email">{{'auth.email' | translate}} </label><i class="bar"></i>
                    <small v-show="errors.has('email')" class="help text-danger">
                        {{ errors.first('email') }}
                    </small>
                </div>
            </div>
            <div class="form-group with-icon-right" :class="{'has-error': errors.has('password'), 'valid': isSuccessfulPasswordValid}">
                <div class="input-group">
                    <input type="password" id="password" v-model="password" name="password" v-validate="'required|min:6'" />
                    <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                    <i class="fa fa-check valid-icon icon-right input-icon"></i>
                    <label class="control-label" for="password">{{'auth.password' | translate}}</label><i class="bar"></i>
                    <small v-show="errors.has('password')" class="help text-danger">
                        {{ errors.first('password') }}
                    </small>
                </div>
            </div>
            <div class="d-flex flex-column flex-lg-row align-items-center justify-content-between down-container">
                <button class="btn btn-primary" type="submit">
                    {{'auth.login' | translate}}
                </button>
               <!-- <router-link class='link' :to="{name: 'signup'}">{{'auth.createAccount' | translate}}</router-link> -->
            </div>
        </form>
    </div>
</template>
<script>
    import {mapState, mapActions} from 'vuex'

    export default {
        name:     'login',
        data() {
            return {
                email:    '',
                password: '',
            }
        },
        methods:  {

            ...mapActions([
                'login',
                'addToastMessage',
            ]),

            onSubmit() {
                this.$validator.validate().then(result => {
                    if (result) {
                        this.login({email:this.email,password:this.password})
                            .then((data) => {
                                this.addToastMessage({
                                    text: data.message,
                                    type: 'success'
                                })
                                this.$router.replace('/')
                            })
                            .catch((data) => {
                                this.error  = data.message
                            })
                    }
                });

            },
            clear(field) {
                this[field] = ''
            },
        },
        computed: {

            ...mapState({
                me: state => state.auth.me,
            }),
            isSuccessfulEmailValid() {
                let isValid = false
                if (this.formFields.email) {
                    isValid = this.formFields.email.validated && this.formFields.email.valid
                }
                return isValid
            },
            isSuccessfulPasswordValid() {
                let isValid = false
                if (this.formFields.password) {
                    isValid = this.formFields.password.validated && this.formFields.password.valid
                }
                return isValid
            },
        },
    }
</script>

<style lang="scss">
    .login {
        @include media-breakpoint-down(md) {
            width: 100%;
            padding-right: 2rem;
            padding-left: 2rem;
            .down-container {
                .link {
                    margin-top: 2rem;
                }
            }
        }
        
        h2 {
            text-align: center;
        }
        width: 21.375rem;
        
        .down-container {
            margin-top: 3.125rem;
        }
    }
</style>
